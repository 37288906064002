import React, { useEffect, useRef, useState } from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { graphql } from 'gatsby'
import { PrincipleIcon } from '../components/graphics/icons'
import Layout from '../components/Layout'
import GridContent from '../components/UI/grid-content'
import { isInViewport } from '../components/Hooks/ViewFunctions'
import { SideShape } from '../components/graphics/landing-shape'
import { useTrail, animated } from 'react-spring'

const StrategyPage = ({ data, location }) => {
  const { investors, introduction, investing, esg } =
    data.allFile.edges[0].node.childMarkdownRemark.frontmatter

  const principleRef = useRef(null)
  const esgRef = useRef(null)
  const [esgIn, setEsgIn] = useState(false)

  const trail = useTrail(esg.goals.length, {
    config: { mass: 10, tension: 500, friction: 10, clamp: true },
    translateY: esgIn ? 0 : -820,
  })

  useEffect(() => {
    const principleNodes = principleRef.current.childNodes
    const layout =
      principleRef.current.parentNode.parentNode.parentNode.parentNode

    function inView() {
      principleNodes.forEach((principle, i) => {
        if (isInViewport(principle)) {
          principle.childNodes[0].childNodes[0].classList.add('appear')
          principle.childNodes[1].childNodes[0].classList.add('appear')
        } else {
          principle.childNodes[0].childNodes[0].classList.remove('appear')
          principle.childNodes[1].childNodes[0].classList.remove('appear')
        }
      })

      if (isInViewport(esgRef.current)) setEsgIn(true)
      else setEsgIn(false)
    }

    layout.addEventListener('scroll', () => inView())

    return () => layout.removeEventListener('scroll', () => inView())
  }, [])

  return (
    <Layout
      location={typeof window !== 'undefined' && location}
      crumbLabel="Our Approach"
    >
      <GatsbySeo
        title="Our Approach"
        description="At Irbisio, our investment approach is distilled in our motto, 'capturing sustainable value'."
      />
      <GridContent
        id="strategy"
        layout="--center-4"
        mode={introduction.mode}
        background={introduction.image}
      >
        {introduction.section && (
          <h4 style={{ textTransform: 'uppercase' }}>{introduction.section}</h4>
        )}
        <h1>{introduction.heading}</h1>
        <p className="markdown">{introduction.intro}</p>
        {introduction.strategy.map(node => {
          return (
            <div key={node.title}>
              <span className="row">
                <PrincipleIcon type={node.icon} />
                <span>
                  <h6>{node.title}</h6>
                </span>
              </span>
              <div
                dangerouslySetInnerHTML={{ __html: node.body }}
                className="markdown"
              />
            </div>
          )
        })}
      </GridContent>

      <GridContent
        id="investing"
        layout="--center-4"
        snapping="no-snapping"
        mode={investing.mode}
        background={investing.image}
      >
        <div>
          {investing.section && <h4>{investing.section}</h4>}
          <h1>{investing.title}</h1>
          <br />
          <p>{investing.body}</p>
        </div>
        <div className="investing-principles" ref={principleRef}>
          {investing.principles.map((principle, i) => {
            return (
              <div
                key={i}
                id={`investing-principle__${i + 1}`}
                className="principle"
                style={{ alignSelf: i % 2 === 1 && 'flex-end' }}
              >
                <div style={{ overflow: 'hidden' }}>
                  {principle.body ? (
                    <h2 className="hidden">{principle.title}</h2>
                  ) : (
                    <h2
                      className="hidden"
                      style={{ fontSize: '2rem', textDecoration: 'underline' }}
                    >
                      {principle.title}
                    </h2>
                  )}
                </div>
                <div style={{ overflow: 'hidden' }}>
                  <p className="hidden">{principle.body}</p>
                </div>
              </div>
            )
          })}
        </div>
        <p>{investing.footer}</p>
        <SideShape />
      </GridContent>

      {/* 
      <GridContent
        id="investors"
        layout="--center-6"
        hide="true"
        mode={investors.mode}
        background={investors.image}
      >
       {investors.section && <h4>{investors.section}</h4>}
        <div dangerouslySetInnerHTML={{ __html: investors.body }} />
        <div>
          <p>Our investors include:</p>
          {investors.details.map(investor => {
            return (
              <div key={investor.name} className="investor-list">
                {investor.logo ? (
                  <img src={investor.logo.publicURL} alt={investor.name} />
                ) : (
                  <h1>{investor.name}</h1>
                )}
                <p className="markdown">{investor.body}</p>
                <a
                  href={investor.button.link}
                  style={{ display: 'block', textAlign: 'end' }}
                >
                  {investor.button.label}
                </a>
              </div>
            )
          })}
        </div>
      </GridContent> */}

      <GridContent
        id="esg"
        layout="--center-4"
        mode={esg.mode}
        background={esg.image}
      >
        {esg.section && <h4>{esg.section}</h4>}
        <h3>{esg.heading}</h3>
        <div className="esg-goals" ref={esgRef}>
          {trail.map(({ ...style }, index) => {
            return (
              <animated.img
                style={{ ...style }}
                src={esg.goals[index].logo.publicURL}
                alt={esg.goals[index].name}
                key={esg.goals[index].name}
                className="mock-button"
              />
            )
          })}
        </div>
      </GridContent>
    </Layout>
  )
}

export default StrategyPage

export const query = graphql`
  query {
    allFile(filter: { name: { eq: "strategy" } }) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              esg {
                section
                mode
                heading
                image {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      width: 800
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
                goals {
                  name
                  logo {
                    publicURL
                  }
                }
              }

              investing {
                body
                footer
                mode
                section
                title
                image {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      width: 800
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
                principles {
                  body
                  title
                }
              }
              investors {
                mode
                section
                body
                image {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      width: 800
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
                details {
                  body
                  name
                  button {
                    label
                    link
                  }
                  logo {
                    publicURL
                  }
                }
              }
              introduction {
                mode
                section
                intro
                heading
                image {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      width: 800
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
                strategy {
                  body
                  title
                  icon
                }
              }
            }
          }
        }
      }
    }
  }
`
